import * as React from 'react'
import { IconProvider, DEFAULT_ICON_CONFIGS } from '@icon-park/react'
import * as IPRICON from '@icon-park/react';
import Icon, {ALL_ICON_KEYS, IconType} from '@icon-park/react/es/all';
const IconConfig = { ...DEFAULT_ICON_CONFIGS, prefix: 'icon' }
export default function KIcon({ name, size, color, strokeWidth = 2, theme ="outline" }) {
    return (
        <i style={{ fontSize: size, color: color }}>
            <Icon type={name} strokeWidth={strokeWidth}  theme={theme}/>
        </i>
    )
}