import * as React from 'react'

export default function AnimatedDiv({name,children}){
    const getAnimated = (e) =>{
        switch(e){
            case 'fadeDown':
                return 'fadeInDown'
            case 'fadeUp':
                return 'fadeInUp'
            case 'bounceIn':
                return 'bounceIn'
            default:
                return name;
        }
    }
    return (
        <div className={'animate__animated '+getAnimated(name)}>
            {children}
        </div>
    )
}