import * as React from "react";
import {useAPI} from "../../controllers/ContextProvider";
import {DashboardLoader} from "./dashboardItems";
import {Grid} from "@mui/material";

export default function DashboardPage() {
    let {get} = useAPI();
    const [page, setPage] = React.useState({dashboards: []});
    React.useEffect(() => {
        get('dashboard/page/getPage/2').then(r => {
            setPage(r.data);
        });
    }, [])
    return (
        <div>
            <Grid container>

                {
                    page.dashboards.map((prop, key) => (
                        <Grid item xs={4} md={4} lg={4}>
                            <DashboardLoader data={prop}/>
                        </Grid>
                    ))
                }
            </Grid>
        </div>
    )
}