import { ErrorComputer } from "@icon-park/react";
import { Stack, Typography } from "@mui/joy";

export default function Four04() {
  return (
    <div>
      <Stack>
        <Stack justifyContent={"center"} spacing={1}>
          <ErrorComputer />
          <Typography>Oops! Page Not Found</Typography>
        </Stack>
      </Stack>
    </div>
  );
}
