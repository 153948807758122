import {Grid} from '@mui/material'
import * as React from 'react'
import KTable from '../components/table';
import {AnimateOnChange} from 'react-animation'
import {useNavigate} from "react-router";

export default function ResponseTabularView({
                                                responses = [], onRowClick = () => {
    }
                                            }) {
    const [headers, setHeaders] = React.useState([]);
    const [body, setBody] = React.useState([]);
    const [, forceUpdate] = React.useState();
    let nav = useNavigate();
    const getHeaders = (response) => {
        let h = [{headerName: "Id", field: "id"}];
        if (response.length > 0) {
            response[0].contents.map((prop, key) => {
                h.push({
                    headerName: prop.question,
                    field: "g" + prop.fieldid,
                    width: 200
                })
            });
        }
        return h;
    };
    const getBody = (pie) => {
        let p = [];
        let d = [];
        // let pop = getHeaders();
        pie.map((file) => {
            let fi = {id: file.tag};
            file.contents.map((content) => {
                fi["g" + content.fieldid] = content.objectresponse[0]
            });
            if (d.includes(file.tag)) {
                let i = d.indexOf(file.tag);
                p[i] = fi;
            } else {
            }
            d.push(file.tag);
            p.push(fi)
        });
        console.log(p);
        return p;
    };
    const getItemByTag = (tag) => {
        for (let i = 0; i < responses.length; i++) {
            if (tag === responses[i].tag) {
                return responses[i];
            }

        }
    }
    React.useEffect(() => {
        setHeaders(getHeaders(responses));
        setBody(getBody(responses));
        forceUpdate({})
    }, []);
    return (
        <div>
            {/*<AnimateOnChange>*/}
                <Grid container>
                    <Grid item xs={12}>
                        <KTable rows={getBody(responses)} onRowClick={(e) => {
                            onRowClick(getItemByTag(e.row.id))
                        }} columns={getHeaders(responses)}/>
                    </Grid>
                </Grid>
            {/*</AnimateOnChange>*/}
        </div>
    )
}
