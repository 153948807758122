import * as React from "react";
import KTable from "../../components/table";
import { Card, Grid,Button } from "@mui/joy";
import { KUtility } from "../../components/utility";
import { useLocation, useNavigate } from "react-router";
import { useAPI } from "../../controllers/ContextProvider";
export function AdsList() {
  let { get } = useAPI();
  let nav = useNavigate();
  const [data, setData] = React.useState([]);
  React.useEffect(() => {
    get("bservices/listAds").then((r) => {
      setData(r.data);
    });
  }, []);
  return (
    <div>
      <Card>
        <KTable columns={[{field:"id",headerName:"id"},
        {field:"name",headerName:"Title"},
        {field:"type",headerName:"Type"},
        {field:"amount",headerName:"Amount"},
        {field:"duration",headerName:"Duration (days)"},
    ]} rows={data} onRowClick={(e) => {
            nav("/forms/ad", {
              state: KUtility.getById(data, "id", e.row.id),
            });
          }}/>
      </Card>
    </div>
  );
}

export function AdView() {
    const data = useLocation().state;
  let { getFile, post } = useAPI();
  const updateStatus = (status)=>{
    post('bservices/approveAd',{status: status,aid: data.id})
  }
    return(
        <div>
            <Grid container>
                <Grid item xs={12} md={4}>
                    <h2>Title</h2>
                    <p>{data.name}</p>
                </Grid>
                <Grid item xs={12} md={4}>
                    <h2>Duration</h2>
                    <p>{data.duration} days</p>
                </Grid>
                <Grid item xs={12} md={4}>
                    <h2>Type</h2>
                    <p>{data.type}</p>
                </Grid>
                <Grid item xs={12} md={4}>
                    <h2>Status</h2>
                    <p>{data.status}</p>
                </Grid>
                <Grid item xs={12} md={4}>
                    <h2>Image</h2>
                    <img src={getFile(data.image)} style={{width:"100%"}}/>
                </Grid>
                <Grid item xs={12} md={12}>
        {/* {data.status === "pending" ? ( */}
          <div>
            <Button onClick={()=>{updateStatus('approved')}} style={{marginRight:10,marginLeft:10}}>Approve Ad</Button>
            <Button onClick={()=>{updateStatus('pending')}} color="neutral" style={{marginRight:10,marginLeft:10}}>Add To pending list</Button>
            <Button onClick={()=>{updateStatus('denied')}} color="danger">Revoke Ad</Button>
          </div>
        {/* // ) : null} */}
      </Grid>
            </Grid>
        </div>
    )
}
