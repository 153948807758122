import { Grid, Stack, Typography } from "@mui/material";
import * as React from "react";
import { FloatingLabelInput } from "../components/input";
import { Login } from "@icon-park/react";
import { Button } from "@mui/joy";
import { Loading } from "../components/loader";
import { useAPI } from "../controllers/ContextProvider";
import { useNavigate } from "react-router";
export default function RegisterUSer() {
  const [formData, setFormData] = React.useState({ gruop: "4" });
  let { get, post, setToken, app, getFile,loaderStyle } = useAPI();
  const [error, setError] = React.useState("");
  let nav = useNavigate();
  const onchange = (e, v) => {
    let f = formData;
    f[e] = v;

    setFormData(f);
  };
  const register = () => {
    Loading.showLoader("Authenticating");
    post("auth/register", formData)
      .then((r) => {
        if (r.data.authorization.token) {
          setToken(r.data.authorization.token);
          nav("/forms");
        } else {
          setError("User Already exists with the same details");
        }
        Loading.hideLoader(loaderStyle);
      })
      .catch((e) => {
        setError("Wrong username or password");
        Loading.hideLoader(loaderStyle);
      });
  };
  return (
    <div>
      <Grid
        container
        className=""
        style={{
          backgroundImage: 'url("'+getFile(app.wallpaper)+'")',
          backgroundSize: "cover",
        }}
      >
        <Grid item xs={12} md={8} lg={8}></Grid>
        <Grid
          item
          xs={12}
          md={4}
          lg={4}
          style={{ boxShadow: "0px 0px 20px #333" }}
        >
          <Stack
            style={{
              height: window.innerHeight,
              width: "100%",
              backgroundColor: "rgba(255,255,255,0.89)",
              justifyContent: "center",
              alignItems: "center",
              backdropFilter: "blur(15px)",
            }}
          >
            <div style={{ padding: 10, width: "80%" }}>
              <form
                action="apk.js"
                onSubmit={(e) => {
                  e.preventDefault();
                }}
              >
                <center>
                  <img
                    src={getFile(app.logo)}
                    style={{ height: 200, width: "auto", borderRadius: 100 }}
                  />
                  <Typography fontWeight={"bold"} variant="h5">
                    {app.name} User Registration form
                  </Typography>
                  <Typography
                    variant="body1"
                    color="#f00"
                    style={{ color: "#f00" }}
                    fontWeight={"bold"}
                  >
                    {error}
                  </Typography>
                </center>
                <FloatingLabelInput
                  required={true}
                  name="name"
                  label={"Full name"}
                  onChange={(e) => {
                    onchange("name", e.target.value);
                  }}
                  type={"text"}
                />
                <FloatingLabelInput
                  required={true}
                  name="email"
                  label={"Email Address"}
                  onChange={(e) => {
                    onchange("email", e.target.value);
                  }}
                  type={"email"}
                />
                <FloatingLabelInput
                  required={true}
                  name="username"
                  label={"Username"}
                  onChange={(e) => {
                    onchange("username", e.target.value);
                  }}
                  type={"text"}
                />
                <FloatingLabelInput
                  required={true}
                  name="password"
                  label={"Password"}
                  onChange={(e) => {
                    onchange("password", e.target.value);
                  }}
                  type={"password"}
                />
                <Stack
                  justifyContent={"space-between"}
                  direction={"row"}
                  alignItems={"center"}
                >
                  <Typography
                    href="#"
                    onClick={() => {
                      nav("/");
                    }}
                  >
                    <b>Already a member?</b>
                  </Typography>
                  <Button
                    type="submit"
                    style={{ float: "right" }}
                    onClick={register}
                    title="Sign Up"
                    endDecorator={<Login />}
                    size="lg"
                    variant="solid"
                  >
                    Sign Up
                  </Button>
                </Stack>
                {/* <FloatingLabelInput required={true} label={"device_id"} onChange={(e) => {
                                onchange(e.target.name,e.target.value)
                            }} type={"text"} />
                            <FloatingLabelInput required={true} label={"Email Address"} onChange={(e) => {
                                onchange(e.target.name,e.target.value)
                            }} type={"email"} /> */}
              </form>
            </div>
          </Stack>
        </Grid>
      </Grid>
    </div>
  );
}
