import { Card, CardContent, Stack, Typography } from '@mui/joy'
import { CardHeader, Grid } from '@mui/material'
import * as React from 'react'
import { Fab } from '@mui/material'
import KIcon from '../../components/iconifier'

import { useLocation } from 'react-router'
import { TabularInput } from '../../components/inputpreview'
export default function AcsolarReportPreview() {
    const data = useLocation().state
    const handleGeneratePdf = () => {
        // const doc = new jsPDF({
        //     format: 'a4',
        //     unit: 'px',

        // });

        // // Adding the fonts.
        // // doc.setFont('Inter-Regular', 'normal');

        // doc.html(reportTemplateRef.current, {
        //     async callback(doc) {
        //         await doc.save('document');
        //     },
        // });
        window.print()
    };
    return (
        <div className='print'>
            <Card>
                <CardContent>
                    <Stack direction={"row"}>
                        <img src='/static/brands/acsolar.jpeg' style={{ width: 100, borderRadius: 10 }} />
                        <h1>Acsolar Company Limited</h1>
                    </Stack>
                    <Typography variant='h1'>
                        Acsolar Weekly Report
                    </Typography>
                    <Grid style={{ color: "#000", fontWeight: "bold" }} container>
                        <Grid item xs={12} md={6} style={{ textAlign: "left" }}>
                            <Typography variant='h6'>Project Name: {data.project.name}</Typography>
                            <Typography variant='h6'>Project Location: {data.project.location}</Typography>
                            <Typography variant='h6'>Project Mason: {data.project.mason}</Typography>
                            <Typography variant='h6'>Project Plumber: {data.project.plumber}</Typography>
                        </Grid>
                        <Grid item xs={12} md={6} style={{ textAlign: "right" }}>
                            <Typography variant='h6'>Commence Date: {data.project.commence_date}</Typography>
                            <Typography variant='h6'>Project Sensitivity: <b>{data.project.sensitivity}</b></Typography>
                            <Typography variant='h6'>{data.project.description}</Typography>
                            <Typography variant='h6'>Report Date:  {data.created_at}</Typography>
                        </Grid>
                    </Grid>
                    <TabularInput dat={data} />
                </CardContent>
            </Card>
            <div style={{ position: "fixed", bottom: 10, right: 10, zIndex: 3 }} className='noprint'>
                <Fab onClick={handleGeneratePdf} color='primary' style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <div style={{ marginTop: 10 }}>
                        <KIcon name='printer' size={30} />
                    </div>
                </Fab>
                {/* <Print */}
            </div>
        </div>
    )
}