import './App.css';
import {KLoader} from './components/loader';
import {KContext} from './controllers/ContextProvider';
import AppRoute from './routes';
import {BrowserRouter} from 'react-router-dom';
// import {FormBuilder} from './panels/forms/formbuilder';
import 'animate.css';
import {SuccessDialog} from './components/dialog';
import {LocalizationProvider} from '@mui/x-date-pickers';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs'

function App() {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <KContext>
                {/* <Display/> */}
                <BrowserRouter>
                    <div className="App">
                        <AppRoute/>
                        <KLoader size={"large"}/>
                        <SuccessDialog/>
                        {/* <FormBuilder/> */}
                    </div>
                </BrowserRouter>
            </KContext>
        </LocalizationProvider>
    );
}

export default App;
