import * as React from 'react'
import { useNavigate } from 'react-router';

export default function Logout() {
    // const [] = React.useState()
    let nav = useNavigate()
    React.useEffect(() => {
        nav('/', { replace: true });
    }, []);
    return (
        <div></div>
    )
}