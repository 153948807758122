import * as React from "react";
import { useLocation, useParams } from "react-router";
import { Grid } from "@mui/material";
import {
  Alert,
  Card,
  CardContent,
  Dropdown,
  Menu,
  MenuButton,
  MenuItem,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import { useAPI } from "../../controllers/ContextProvider";
import { Button } from "@mui/joy";
import {
  MapData,
  TextData,
  FileData,
  ImageData,
  ListTextData,
  TabularInput,
  GetPreview,
} from "../../components/inputpreview";
import KTabPanel from "../tabpanel";
import FormTab from "./formtab";
import TabPanel from "@mui/joy/TabPanel";
import { More, MoreTwo } from "@icon-park/react";

export default function FeedbackPreviewer() {
  let data = useLocation().state;
  const [tabheads, setTabHeads] = React.useState([]);
  const [tabBodies, setTabBodies] = React.useState([]);
  const [, forceUpdate] = React.useState();
  let { tag } = useParams();
  React.useEffect(() => {
    setTabHeads([]);
    setTabBodies([]);
    setTimeout(() => {
      let p = [data.data.topic.topic.name];
      let tp = [
        <TabPanel value={0}>
          <ParentFeedback data={data.data} />
        </TabPanel>,
      ];
      let i = 1;
      data.data.topic.children.forEach((t) => {
        p.push(t.name);
        tp.push(
          <TabPanel value={i}>
            <FormTab child={true} form={t.page} parent={tag} />
          </TabPanel>
        );
        i++;
      });
      setTabBodies(tp);
      setTabHeads(p);
      forceUpdate({});
    }, 2000);
  }, [tag]);
  return (
    <div>
      <KTabPanel tabheads={tabheads} tabcomps={tabBodies} id={data.data.tag} />
    </div>
  );
}
function ParentFeedback({ data }) {
  let { get, post, setFormId, setShowForm, setAdditionalFormData } = useAPI();
  const [contents, setContents] = React.useState([]);
  React.useEffect(() => {
    let p = [];
    data.contents.forEach((t) => {
      if (t.question !== "parent_tag") {
        p.push(t);
      }
    });
    setContents(p);
  }, []);
  console.log(data);
  return (
    <div>
      <h4></h4>
      <Grid container spacing={2}>
        {/* <Grid item xs={12} md={4}></Grid> */}
        <Grid style={{ textAlign: "left" }} item xs={12} md={12}>
          <Stack justifyContent={"space-between"} direction="row">
            <h3>{data.topic.topic.name}</h3>
            <FormActions actions={data.topic.topic.actions} tag={data.tag} />
          </Stack>
        </Grid>
        <Grid item xs={12} md={12}></Grid>
        {data.topic.children.map((prop, key) => (
          <Grid item xs={4} md={2} key={key}>
            <Button
              onClick={() => {
                setFormId(prop.page);
                setAdditionalFormData({ parent_tag: data.tag });
                setShowForm(true);
              }}
            >
              Add {prop.name}
            </Button>
          </Grid>
        ))}
        <Grid item xs={12} md={12}></Grid>
        {contents.map((prop, key) => (
          <Grid
            item
            xs={prop.rform.styles.xs}
            md={prop.rform.styles.md}
            lg={prop.rform.styles.lg}
          >
            <Card style={{ backgroundColor: "#e4e4e4" }}>
              <h4>{prop.rform.name}</h4>
              <GetPreview
                name={prop.rform.name}
                data={prop.objectresponse}
                type={prop.rform.type.name}
              />
            </Card>
          </Grid>
        ))}
      </Grid>
    </div>
  );
}
function FormActions({ actions, tag }) {
  let { post } = useAPI();
  const updateItem = (data) => {
    post("forms/update/attributes", {
      tag: tag,
      constraints: data.constraints,
      gattributes: data.attributes,
    }).then((r) => {});
  };
  return (
    <Dropdown>
      <MenuButton>
        <MoreTwo />
      </MenuButton>
      <Menu>
        {actions.map((prop) => (
          <MenuItem
            onClick={() => {
              updateItem(prop);
            }}
          >
            {prop.name}
          </MenuItem>
        ))}
      </Menu>
    </Dropdown>
  );
}
