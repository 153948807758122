import * as React from "react";
import KTabPanel from "../tabpanel";
import TabPanel from "@mui/joy/TabPanel";
import KTable from "../../components/table";
import { useAPI } from "../../controllers/ContextProvider";
import { Button } from "@mui/joy";
import { Stack } from "@mui/material";
function PermissionHome() {
  return (
    <div>
      <KTabPanel
        tabheads={[
          "Permission List",
          "Permission Templates",
          "User Permissions",
        ]}
        tabcomps={[
          <TabPanel value={0}>
            <PermissionsManager />
          </TabPanel>,
          <TabPanel value={1}>
            <PermissionsTemplate />
          </TabPanel>,
          <TabPanel value={2}>
            <UserPermissions />
          </TabPanel>,
        ]}
      />
    </div>
  );
}

function PermissionsManager() {
  const [permissions, setPermissions] = React.useState([]);
  let {
    get,
    post,
    setShowForm,
    setFormId,
    setOnFormSubmit,
    showForm,
    getFormConfig,
  } = useAPI();
  const getDefinitions = () => {
    get("auth/permissions/list/all").then((r) => {
      setPermissions(r.data);
    });
  };
  React.useEffect(() => {
    getDefinitions();
  }, [showForm]);
  const loadQnPermsssions=()=>{
    get('appconfig/createQnPermissions').then((r)=>{
      getDefinitions();
    });
  }
  return (
    <div>
      <Stack direction="row" spacing={3}>
        <Button
          style={{ marginBottom: 5 }}
          onClick={() => {
            setShowForm(true);
            setFormId(getFormConfig("cperm"));
          }}
        >
          Add new Permission
        </Button>
        <Button
          style={{ marginBottom: 5 }}
          onClick={() => {
            loadQnPermsssions();
          }}
        >
          Load Permissions from Questionnaires
        </Button>
      </Stack>

      <KTable
        rows={permissions}
        columns={[
          { field: "id", headerName: "ID" },
          { field: "name", headerName: "Name", width: 300 },
          { field: "platform", headerName: "Platform", width: 100 },
          { field: "acces_type", headerName: "Access Type", width: 100 },
          { field: "grant_type", headerName: "Grant Type", width: 300 },
          { field: "code", headerName: "Code", width: 300 },
        ]}
      />
    </div>
  );
}
function PermissionsTemplate() {
  const [templates, setTemplates] = React.useState([]);
  let { get, post, setShowForm, setFormId, getFormConfig, showForm } = useAPI();
  const getTemplates = () => {
    get("auth/permissions/list/templates").then((r) => {
      setTemplates(r.data);
    });
  };
  React.useEffect(() => {
    getTemplates();
  }, [showForm]);
  return (
    <div>
      <Button
        style={{ marginBottom: 5 }}
        onClick={() => {
          setShowForm(true);
          // setOnFormSubmit(getDefinitions);
          setFormId(getFormConfig("cpermtemp"));
        }}
      >
        Add Template
      </Button>
      <KTable
        rows={templates}
        columns={[
          { field: "name", headerName: "Name", width: 300 },
          { field: "definitions", headerName: "Permissions", width: 600 },
        ]}
      />
    </div>
  );
}
function UserPermissions() {
  const [user, setUserPermission] = React.useState([]);
  let { get, post, setShowForm, setFormId, showForm, getFormConfig } = useAPI();
  const getTemplates = () => {
    get("auth/permissions/list/app-permissions").then((r) => {
      setUserPermission(r.data);
    });
  };
  React.useEffect(() => {
    getTemplates();
  }, [showForm]);
  return (
    <div>
      <Button
        style={{ marginBottom: 5 }}
        onClick={() => {
          setShowForm(true);
          setFormId(getFormConfig("assignTemp"));
        }}
      >
        Assign User Permission
      </Button>
      <KTable
        rows={user}
        columns={[
          { field: "name", headerName: "Name", width: 300 },
          { field: "user", headerName: "User", width: 400 },
        ]}
      />
    </div>
  );
}
const checkPermissions = (permissionName) => {};
export { PermissionHome };
