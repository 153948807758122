import { Grid, Stack } from "@mui/material";
import * as React from "react";
import {
  Card,
  Button,
  Typography,
  Divider,
  Input,
  Switch,
  CardActions,
  IconButton,
  Textarea,
} from "@mui/joy";
import KTable from "../../components/table";
import { DSelect, GetInput } from "./formbuilder";
import { useAPI } from "../../controllers/ContextProvider";
import { KSelect } from "../../components/input";
import { Delete, Edit, Plus } from "@icon-park/react";
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism.css'

export default function FormCreator() {
  const [show, setShow] = React.useState(false);
  return (
    <Card>
      <Stack direction={"row"}>
        <Button
          onClick={() => {
            setShow(true);
          }}
        >
          Create Form
        </Button>
      </Stack>
      <KTable
        rows={[]}
        columns={[
          { field: "id", headerName: "Id" },
          { field: "name", headerName: "Name" },
          { field: "created_date", headerName: "Created date" },
        ]}
      />
      {show ? (
        <FormStudio
          close={() => {
            setShow(false);
          }}
        />
      ) : null}
    </Card>
  );
}

function FormStudio({ close = () => {} }) {
  const [selectedField, setSelectedField] = React.useState(null);
  const [existingFields, setExistingFields] = React.useState([]);
  const [fields, setFields] = React.useState([]);
  const [types, setTypes] = React.useState([]);
  const [options, setOptions] = React.useState([]);
  const [, forceUpdate] = React.useState();
  const [selectedType, setSelectedType] = React.useState({});
  let { get } = useAPI();
  const addNewField = (type, template = null) => {
    let f = fields;
    if (template !== null) {
      f.push(template);
    } else {
      f.push(getInputTemplate(type,true,false));
    }
    setFields(f);
    forceUpdate({});
  };
  const selectType = (i) => {
    types.forEach((f) => {
      if (f.id == i) {
        setSelectedType(f);
      }
    });
    forceUpdate({});
  };
  const addExistingField = () => {
    existingFields.forEach((t) => {
      if (t.id == selectedField) {
        fields.push(getInputTemplate(t,false,false,t));
        setFields(fields);
      }
    });
  };
  const updateField = (data, key) => {
    let f = fields;
    f[key] = data;
    setFields(f);
    forceUpdate({});
  };
  const removeField = (key) => {
    console.log(key)
    let p = fields;
    p.splice(key,1);
    setFields(p);
    forceUpdate({});
  };
  const getTypes = () => {
    get("forms/list/fieldtypes").then((r) => {
      let p = r.data;
      let data = [];
      p.forEach((t) => {
        t["value"] = t.id;
        data.push(t);
      });
      setTypes(data);
      forceUpdate({});
    });
  };
  const getOptions = () => {
    get("forms/list/options").then((r) => {
      let p = r.data;
      let data = [];
      p.forEach((t) => {
        t["value"] = t.id;
        data.push(t);
      });
      setOptions(data);
    });
  };
  const getFields = () => {
    get("forms/list/fields").then((r) => {
      let p = [];
      r.data.forEach((prop, key) => {
        prop["value"] = prop.id;
        p.push(prop);
      });
      setExistingFields(p);
      forceUpdate({})
    });
  };
  React.useEffect(() => {
    getTypes();
    getOptions();
    getFields();
  }, []);
  return (
    <Card
      style={{
        bottom: 0,
        right: 0,
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        padding: 0,
      }}
    >
      <Stack direction={"row"} spacing={2}>
        <Stack
          // justifyContent={"space-between"}
          style={{
            position: "sticky",
            backgroundColor: "#e3e3e3",
            zIndex: 30,
            height: window.innerHeight,
            width: "20%",
          }}
          direction={"column"}
          padding={2}
        >
          <Grid container spacing={4}>
            <Grid justifyContent={"center"} alignItems={"center"} item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <DSelect
                    e={{ id: "as", name: "Choose field type", options: types }}
                    options={types}
                    onChange={(e, v) => {
                      selectType(v);
                    }}
                    name={"Choose Field type"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    onClick={() => {
                      addNewField(selectedType);
                    }}
                  >
                    Add New Field
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs={8}>
                  <DSelect
                    onChange={(e, v) => {
                      setSelectedField(v);
                    }}
                    e={{
                      name: "Existing field",
                      id: "0",
                      options: existingFields,
                    }}
                    options={existingFields}
                    name={"Existing field"}
                  />
                </Grid>
                <Grid item xs={4}>
                  <Button
                    onClick={() => {
                      addExistingField();
                    }}
                  >
                    Add Field
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Stack justifyContent={"space-between"} direction={"row"}>
                <Button>Add Type</Button>
              </Stack>
            </Grid>
            <Grid item xs={12}>
              <Optionfier getFields={getOptions} />
            </Grid>
            <Grid item xs={12}>
              <FormActions/>
            </Grid>
            <Grid item xs="12">
              <Button onClick={close} color="danger">
                Close
              </Button>
            </Grid>
          </Grid>
        </Stack>
        <Grid
          style={{ overflow: "auto", maxHeight: window.innerHeight }}
          spacing={2}
          container
        >
          <Grid item xs={12}>
            <Grid container spacing={4}>
              <Grid item xs={6}>
              <Input type="text" size="lg" placeholder="Title" />
              </Grid>
              <Grid item xs={6} paddingRight={3}>
                    <DSelect e={{id:"te",name:"Actions", options:[], multiple:"1"}}/>
              </Grid>
            </Grid>
          </Grid>
          {fields.map((prop, key) => (
            <Grid
              item
              xs={prop.styles.xs}
              md={prop.styles.md}
              sm={prop.styles.sm}
              lg={prop.styles.lg}
              xl={prop.styles.xl}
            >
              <FormElement
                types={types}
                data={prop}
                key={key}
                getOptions={getOptions}
                options={options}
                removeField={()=>{
                  removeField(key)
                }}
                onChange={(data) => {
                  updateField(data, key);
                }}
              />
            </Grid>
          ))}
        </Grid>
      </Stack>
    </Card>
  );
}
function FormActions(data, onChange){
  const [code, setCode] = React.useState(
    `function add(a, b) {\n  return a + b;\n}`
  );
  return(
    <Stack spacing={1}>

      <Input placeholder="Action name"/>
      <Editor
      value={code}
      onValueChange={code => setCode(code)}
      highlight={code => highlight(code, languages.js)}
      padding={10}
      style={{
        fontFamily: '"Fira code", "Fira Mono", monospace',
        fontSize: 12,
        backgroundColor:"#fff"
      }}
    />
    </Stack>
  )
}
function FormElement({
  data,
  onChange,
  key,
  types,
  removeField,
  isChild = false,
  isNew= true,
  getOptions = () => {},
  options = [],
}) {
  const [field, setField] = React.useState(data);
  const [showEdit, setShowEdit] = React.useState(false);
  const [, forceUpdate] = React.useState();
  console.log(data)
  const updateStyles = (key, val) => {
    let p = field;
    p.styles[key] = val;
    setField(p);
    onChange(p);
    forceUpdate({});
  };
  const updateRules = (val) => {
    let p = field;
    p.rules = val;

    let c = {};
    p.rules.map((prop) => {
      c[prop.key] = prop.value;
    });
    p.c_rules = c;

    setField(p);
    onChange(p);
    forceUpdate({});
  };
  const updateNormal = (key, val) => {
    let p = field;
    p[key] = val;
    setField(p);
    onChange(p);
    forceUpdate({});
  };
  const updateType = (i) => {
    types.forEach((f) => {
      if (f.id === i) {
        setField(getInputTemplate(f, isNew,isChild,field));
        onChange(field);
      }
      forceUpdate({});
    });
  };
  const updateOptions = (val)=>{
    options.forEach((f)=>{
      if(f.value===val){
        console.log(f)
        let p = field;
        let px =[]
        f.options.forEach((j)=>{
          px.push({name: j, value:j});
        });
        p.options = px
        p["selectedOption"] = val
        setField(p);
        onChange(p);
        forceUpdate({});
      }
    })
  }
  return (
    <Card>
      <GetInput
        input={field}
        multiple={field.Multiple}
        onChange={() => {}}
        type={field.type.name}
      />
      <Divider />
      {showEdit ? (
        <Grid spacing={1} container>
          <Grid item xs={12} md={4}>
            <Stack spacing={1}>
              <Typography>Preview</Typography>
              <Divider />

              <Input
                defaultValue={field.name}
                onChange={(e) => {
                  updateNormal("name", e.target.value);
                }}
                placeholder="Display Name"
              />
              <Input
                defaultValue={field.map_name}
                placeholder="Mapping Name"
                onChange={(e) => {
                  updateNormal("name", e.target.value);
                }}
              />
              <Input
                defaultValue={field.error}
                placeholder="Error message"
                onChange={(e) => {
                  updateNormal("error_txt", e.target.value);
                }}
              />
              <DSelect
                e={{options:types, name:"Type", id:"d0"}}
                defaultValue={field.type}
                onChange={(e, v) => {
                  updateType(v);
                }}
              />
              <div style={{ padding: 10, zIndex: 400 }}>
                <DSelect
                  e={{ id: "0", name: "Choose Options", options: options }}
                  onChange={(e, v) => {
                    updateOptions(v);
                  }}
                />
              </div>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing={1}>
              <Typography>Styles & Rules</Typography>
              <Divider />
              <ShowRules rules={field.rules} updateRules={updateRules} />
              <Divider />
              <Stack direction={"row"} spacing={1}>
                {field.style_rules.map((prop, key) => (
                  <Input
                    placeholder={prop.key}
                    defaultValue={prop.value}
                    onChange={(e) => {
                      updateStyles(prop.key, e.target.value);
                    }}
                  />
                ))}
              </Stack>
            </Stack>
          </Grid>
          <Grid item xs={12} md={4}>
            <Stack spacing={1}>
              <Typography>Options</Typography>
              <Divider />
              <Optionfier
                getFields={getOptions}
                initOptions={options}
                onChange={updateNormal}
              />
            </Stack>
          </Grid>
        </Grid>
      ) : null}
      <Divider />
      <CardActions>
        <Stack direction={"row"}>
          <IconButton
            size="small"
            onClick={() => {
              setShowEdit(!showEdit);
            }}
          >
            <Edit />
          </IconButton>
          <IconButton
            size="small"
            onClick={() => {
              removeField(key);
            }}
          >
            <Delete />
          </IconButton>
        </Stack>
      </CardActions>
    </Card>
  );
}
function ShowRules({ rules, updateRules }) {
  const updateRule = (name, value) => {
    let it = [];
    rules.forEach((i) => {
      if (i.key == name) {
        i.value = value;
      }
      it.push(i);
    });
    updateRules(it);
  };
  const [rule, setRule] = React.useState("");
  const [ruleType, setRuleType] = React.useState("");
  const [, forceUpdate] = React.useState();
  const addRule = () => {
    let p = rules;
    p.push({ key: rule, value: "", type: ruleType });
    updateRules(p);
    forceUpdate({});
  };
  const RuleView = ({ data }) => {
    switch (data.type) {
      case "string":
        return (
          <Input
            defaultValue={data.value}
            placeholder={data.key}
            onChange={(e) => {
              updateRule(data.key, e.target.value);
            }}
          />
        );
      case "boolean":
        return (
          <Stack
            direction={"row"}
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Typography>{data.key}</Typography>
            <Switch
              onChange={(e) => {
                alert(e.target.value);
              }}
              placeholder={data.key}
            />
          </Stack>
        );
    }
  };
  return (
    <div>
      <Stack spacing={1}>
        {rules.map((prop, key) => (
          <RuleView data={prop} />
        ))}
        <Stack direction={"row"} spacing={1}>
          <KSelect
            onChange={(e, v) => {
              setRuleType(v);
              forceUpdate({});
            }}
            name={"Type"}
            options={[
              {
                name: "boolean",
                value: "boolean",
                id: "boolean",
              },
              {
                name: "string",
                value: "string",
                id: "string",
              },
            ]}
          ></KSelect>
          <Input
            placeholder={"Add rule"}
            onChange={(e) => {
              setRule(e.target.value);
            }}
          />
          <Button
            onClick={() => {
              addRule();
            }}
            variant="outlined"
          >
            Add Rule
          </Button>
        </Stack>
      </Stack>
    </div>
  );
}

function Optionfier({ getFields = () => {} }) {
  const [optText, setOptText] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [newOpts, setOpts] = React.useState([]);
  const { post } = useAPI();
  const addOpt = () => {
    let p = optText.split(",");
    setOpts(p);
    p.concat(newOpts);
    setOpts(p);
    post("forms/create/option", { options: p, name: title }).then(() => {
      getFields();
    });
  };
  return (
    <div>
      <div
        style={{
          position: "relative",
        }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography>Add Options</Typography>
          </Grid>
          <Grid item xs={12} md={12}>
            <Input
              id="title"
              placeholder="Title"
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={10}>
            <Textarea
              id="option"
              placeholder="Options (comma separated)"
              onChange={(e) => {
                setOptText(e.target.value);
              }}
            />
          </Grid>
          <Grid item xs={12} md={2}>
            <Button
              fullWidth
              onClick={() => {
                addOpt();
              }}
            >
              <Plus size={25} />
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

const getInputTemplate = (
  type,
  isNew = true,
  child = false,
  defaultValue = {
    name: "Untitled",
    map_name: "",
    error_txt: "This field is required",
    style_rules: [
      { key: "xs", value: "12" },
      { key: "sm", value: "12" },
      { key: "md", value: "12" },
      { key: "lg", value: "12" },
      { key: "xl", value: "12" },
    ],
    rules: [
      { key: "Multiple", value: "value", type: "boolean" },
      { key: "Required", value: "value", type: "boolean" },
      { key: "group", value: "value", type: "boolean" },
      { key: "table_inputs", value: "value", type: "string" },
    ],
  }
) => {
  if (!isNew) {
    let p = defaultValue;
    p["style_rules"] = [
      { key: "xs", value: "12" },
      { key: "sm", value: "12" },
      { key: "md", value: "12" },
      { key: "lg", value: "12" },
      { key: "xl", value: "12" },
    ];
    p["map_name"] = "";
    p["isNew"] = false;
    return p;
  }
  let initTemplate = {
    id: 0,
    name: defaultValue.name,
    map_name: "",
    isNew: true,
    error_txt: "This field is required",
    type: type,
    options: [],
    style_rules: defaultValue.style_rules,
    styles: { xs: 12, sm: 12, md: 12, lg: 12, xl: 12 },
    rules: defaultValue.rules,
  };
  let isChild = [
    { key: "parent", value: "value", type: "boolean" },
    { key: "parent_element", value: "value", type: "fields" },
    { key: "parent_value", value: "value", type: "string" },
  ];
  if (child) {
    initTemplate.rules.concat(isChild);
  }
  switch (type.name) {
    case "gpsimage":
    case "file":
    case "image":
      initTemplate.rules.push({
        key: "accept",
        value: "image/*",
        type: "string",
      });
      break;
    case "DatabaseSelect":
      initTemplate.rules.concat([
        { key: "db", value: "value", type: "string" },
        { key: "field", value: "value", type: "string" },
        { key: "topic", value: "value", type: "string" },
      ]);
      break;
    default:
      break;
  }
  let p = {};
  initTemplate.rules.map((prop) => {
    p[prop.key] = prop.value;
  });
  initTemplate["c_rules"] = p;
  return initTemplate;
};
