import { Typography } from "@mui/joy";
import * as React from "react";
import { LineWave } from "react-loader-spinner";
import Card from "@mui/joy/Card";
import { useAPI } from "../controllers/ContextProvider";
export function KLoader({ size = "large" }) {
    let {navIsOpen}= useAPI()
  const styles = {
    large: {
      height: "100%",
      backdropFilter: "blur(5px)",
      display: "none",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: "rgba(255,255,255,0.7)",
      justifyContent: "center",
      alignItems: "center",
      zIndex: 233,
    },
    window: {
      display: "none",
      height: "92%",
      width: window.outerWidth*(navIsOpen?0.82:0.5),
      position:"absolute",
      zIndex:233,
      backdropFilter: "blur(5px)",
      backgroundColor: "rgba(255,255,255,0.7)",
      justifyContent: "center",
      alignItems: "center",
    },
  };
  return (
    <div id={"kloader" + size} style={styles[size]}>
      <div
        style={{
          display: "none",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <LineWave
          height="90"
          width="90"
          color="#0078d7"
          ariaLabel="triangle-loading"
          wrapperStyle={{}}
          wrapperClassName=""
          visible={true}
        />
        <Typography style={{textAlign:"center"}} id={"kloadmessage" + size}></Typography>
      </div>
    </div>
  );
}

export class Loading {
  static showLoader(message, size = "large") {
    document.getElementById("kloader" + size).style.display = "flex";
    document.getElementById("kloadmessage" + size).innerHTML = message;
  }
  static hideLoader(size = "large") {
    document.getElementById("kloader" + size).style.display = "none";
  }
}
