import { Alert, Card, CardContent, Stack, Typography } from '@mui/joy'
import * as React from 'react'
import { MapData, TextData, FileData, ImageData, ListTextData, TabularInput, GetPreview } from '../../components/inputpreview'
import { useLocation } from 'react-router'
import jsPDF from 'jspdf'
import { useRef } from 'react'
import KIcon from '../../components/iconifier'
export default function ResponsePreview({data}) {

    const doc = new jsPDF();
    const reportTemplateRef = useRef(null);

    const handleGeneratePdf = () => {
        // const doc = new jsPDF({
        //     format: 'a4',
        //     unit: 'px',

        // });

        // // Adding the fonts.
        // // doc.setFont('Inter-Regular', 'normal');

        // doc.html(reportTemplateRef.current, {
        //     async callback(doc) {
        //         await doc.save('document');
        //     },
        // });
        window.print()
    };
    return (
        <div>

            <div ref={reportTemplateRef} className='print'>
                {/* <div><h4>gg</h4></div> */}

                <Card >
                    <CardContent>
                        <Alert variant='soft' color='primary' style={{ textAlign: "left" }}>
                            <img src='/static/brands/acsolar.jpeg' style={{ width: 100, borderRadius: 10 }} />
                            <Stack>

                                <Typography fontWeight={"bold"}>
                                </Typography>
                                <h1>{data.topic.topic.name}</h1>
                                <Typography>Report generation by Acsolar V1.0.2</Typography>
                            </Stack>
                        </Alert>
                        <Stack>
                            {
                                data.contents.map((prop) => (
                                    <Alert variant='soft' style={{ margin: 4, display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                                        <div style={{ padding: 10, justifyContent: "block", textAlign: "left" }}>
                                            <Typography className='textPrint' fontWeight={"bold"}>{prop.question}</Typography>
                                        </div>
                                        <GetPreview data={prop.objectresponse} type={prop.rform.type.name} />
                                    </Alert>
                                ))
                            }
                        </Stack>
                    </CardContent>
                </Card>
            </div>

        </div>
    )
}

