import { Card } from "@mui/joy";
import * as React from "react";
import { Triangle, BallTriangle, ColorRing } from "react-loader-spinner";
import { useAPI } from "../controllers/ContextProvider";
import { useNavigate, useParams } from "react-router";
export default function WelcomePage() {
  let { get, setApp } = useAPI();
  let nav = useNavigate()
  let params = useParams();
  React.useEffect(() => {
    get(`appconfig/loadApp/${params.tag}`).then((r)=>{
        setApp(r.data);
    }).then(()=>{
        nav('/');
    });
  }, []);
  return (
    <div
      style={{
        height: window.innerHeight,
        justifyContent: "center",
        alignItems: "center",
        display: "flex",
      }}
    >
      <Card style={{ width: 400 }}>
        <center>
          <ColorRing />
          <p>Please Wait While We load your app ...</p>
        </center>
      </Card>
    </div>
  );
}
