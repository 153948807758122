import { CheckCorrect } from '@icon-park/react'
import { Card, CardContent, Typography } from '@mui/joy'
import { Stack } from '@mui/material'
import * as React from 'react'

function SuccessDialog(){
    return(
        <div id="succ_diag" style={{zIndex:900,backgroundColor:"rgba(255,255,255,0.6)",backdropFilter:"blur(20px)",position:"fixed",top:0,left:0,bottom:0,right:0, justifyContent:"center",alignItems:"center", display:"none"}}>
            <div style={{marginLeft:100,marginRight:100}}>

            <Card>
                <CardContent>
                    <Stack alignItems={"center"} justifyContent={"center"}>
                    <CheckCorrect style={{color:"#0f0"}} color='#0f0' size={50}/>
                    <Typography>Operation Completed successfully</Typography>
                    </Stack>
                </CardContent>
            </Card>
            </div>
        </div>
    )
}
function showDialog(){
    document.getElementById("succ_diag").style.display="flex";
}
function hideDialog(){
    document.getElementById("succ_diag").style.display="none";
}
export {SuccessDialog, showDialog, hideDialog}