import axios from "axios";
import React, { createContext, useContext } from "react";
import { Loading } from "../components/loader";
const UserContext = createContext();
export const KContext = ({ children }) => {
  // const APIURL = 'https://forms.hotpot.co.tz/api/'
  // const APIURL = 'http://154.118.227.142:8000/ilmis_back/api/'
  // const host = 'http://localhost:8000/'
  // const host = 'http://154.118.227.29:8083/'
  // const host = 'https://management-api.smartccf.or.tz/'
  const host = "https://api.bservices.co.tz/";

  const APIURL = host + "api/";
  // const APIURL = 'http://154.118.227.29/api/'

  const FILEURL = host + "storage/";
  // const FILEURL = 'http://154.118.227.29/storage/'
  const storageUrl = APIURL;
  const [app, setApp] = React.useState({
    name: "BServices",
    desription: "",
    logo: "uploads/bservices.png",
    wallapaper: "",
  });
  const [loaderStyle, setLoaderStyle] = React.useState("large");
  const [token, setToken] = React.useState(null);
  const [isAuth, setAuth] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [topics, setTopics] = React.useState([]);
  const [showForm, setShowForm] = React.useState(false);
  const [formId, setFormId] = React.useState(0);
  const [appConfig, setAppConfig] = React.useState({
    sidebar: [],
    formConfig: {},
  });
  const [user, setUser] = React.useState({ name: "", role: "" });
  const [additionalFormData, setAdditionalFormData] = React.useState({});
  const [navIsOpen, setNav] = React.useState(true);

  const [business, setBusiness] = React.useState({
    name: "Acsolar Company Limited",
    id: 1,
  });
  const [currentProject, setCurrentProject] = React.useState(1);
  const [formRequestData, setFormRequestData] = React.useState({
    url: "forms/getForm/1",
    formId: 0,
    hasAddon: false,
    addon: "",
    addonType: "",
    addonValue: "",
    callback: () => {},
  });
  const get = (url, loading = true) => {
    Loading.showLoader("Loading", loaderStyle);
    let _token = token
    if(_token===null){
      _token=reloadToken()
    }
    return axios
      .get(APIURL + url, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + _token,
        },
      })
      .then((r) => {
        setTimeout(() => {
          Loading.hideLoader(loaderStyle);
        }, 500);
        return r;
      })
      .catch((e) => {
        console.log(e);
        Loading.hideLoader(loaderStyle);
      });
    // axios.post()
  };
  const loadToken = (toke,user)=>{
    sessionStorage.setItem("jwt_token",toke);
    sessionStorage.setItem("user",JSON.stringify(user));
    // localStorage.setItem()
  }
  const reloadToken = ()=>{
    let tok =sessionStorage.getItem("jwt_token")
    let user =JSON.parse(sessionStorage.getItem("user"))
    setUser(user);
    return tok;
    
  }
  const updateFormRequest = (
    url = 0,
    formId,
    hasAddon = false,
    addonType = "0",
    addonValue = "0",
    callback = () => {}
  ) => {
    setShowForm(true);
    setFormId(0);
    setFormRequestData({
      url: url,
      formId: formId,
      hasAddon: hasAddon,
      addonType: addonType,
      addonValue: addonValue,
      callback: callback,
    });
  };
  const post = (url, data) => {
    let _token = token
    if(_token===null){
      _token=reloadToken()
    }
    return axios
      .post(APIURL + url, data, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + _token,
        },
      })
      .catch((e) => {
        Loading.hideLoader(loaderStyle);
      });
  };
  const upload = (url, data) => {
    let _token = token
    if(_token===null){
      _token=reloadToken()
    }
    return axios
      .post(APIURL + url, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: "Bearer " + _token,
        },
      })
      .catch((e) => {
        Loading.hideLoader(loaderStyle);
      });
  };
  const getFile = (url) => {
    return FILEURL + url;
  };
  const getFormConfig = (name) => {
    return appConfig.formConfig[name];
  };
  return (
    <UserContext.Provider
      value={{
        app,
        navIsOpen,
        setNav,
        setApp,
        currentProject,
        getFormConfig,
        getFile,
        updateFormRequest,
        formRequestData,
        setCurrentProject,
        business,
        setBusiness,
        additionalFormData,
        setAdditionalFormData,
        formId,
        setFormId,
        showForm,
        setShowForm,
        upload,
        topics,
        setTopics,
        token,
        isAuth,
        user,
        post,
        get,
        setAuth,
        setToken,
        setUser,
        setLoading,
        loading,
        appConfig,
        setAppConfig,
        storageUrl,
        APIURL,
        loaderStyle,
        loadToken,
        setLoaderStyle
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
export const useAPI = () => {
  return useContext(UserContext);
};
