import {DataDisplay, Form, Plus} from '@icon-park/react'
import {Button, CardContent, Card, Typography, ToggleButtonGroup} from '@mui/joy'
import {Accordion, AccordionDetails, Stack} from '@mui/material'
import *as React from 'react'
import {useAPI} from '../../controllers/ContextProvider'
import KTable from '../../components/table'
import {KForm} from '../forms/formbuilder'
import ResponsePreview from '../forms/responsepreview'

export function TaskView({}) {
    let {setFormId, setShowForm, business, setAdditionalFormData} = useAPI()
    return (
        <div>
            <Button onClick={() => {
                setAdditionalFormData({business: business.id});
                setFormId(1);
                setShowForm(true)
            }} startDecorator={<Plus/>}>Add Task</Button>
            <br/>
        </div>
    )
}

export function SubTask({task, project, children}) {
    const [view, setView] = React.useState('form');
    return (
        <div>
            <Stack style={{
                height: 70,
                alignItems: "center",
                justifyContent: "flex-start",
                flexDirection: "row",
                backgroundColor: "#eee"
            }}>
                <div style={{paddingLeft: 10}}>
                    <Typography fontWeight={"bold"} fontSize={20}>Sub-Task : {task.name}</Typography>
                </div>
            </Stack>
            <div>
                <div style={{padding: 10}}>
                    <div>
                        <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} marginTop={5}>
                            <ToggleButtonGroup value={view} onChange={(v, val) => {
                                setView(val)
                            }}>
                                <Button startDecorator={<DataDisplay/>} value="data">Data View</Button>
                                <Button startDecorator={<Form/>} value="form">Form View</Button>
                            </ToggleButtonGroup>
                        </Stack>
                        <div style={{padding: 10}}>
                            {view === 'form' && task.task_form!==null?
                                <KForm additionalData={{project: project.id, task: task.id}} form={task.task_form}/> :
                                <div>
                                    {
                                        task.task_data.map((prop, key) => (
                                            <Accordion title='kk'>
                                                <AccordionDetails>
                                                    <ResponsePreview data={prop}/>
                                                </AccordionDetails>
                                            </Accordion>
                                        ))
                                    }
                                </div>
                            }
                        </div>
                        {children}
                    </div>
                </div>
                {
                    task.sub_task.map((prop) => (
                        <SubTask task={prop}/>
                    ))
                }
            </div>
        </div>
    )
}

export function Task({children, title, project, task}) {
    let {setAdditionalFormData, updateFormRequest} = useAPI()
    const [view, setView] = React.useState('data');
    return (
        <div>

            <Stack direction={"row"} style={{
                borderLeft: "12px solid #0078d7",
                alignItems: "center",
                justifyContent: "space-between",
                backgroundColor: "#ddd",
                height: 70,
                boxShadow: "0 0 5px 0px"
            }}>
                <div style={{paddingLeft: 10}}>
                    <Typography fontWeight={"bold"} fontSize={20}>Task : {title}</Typography>
                </div>
                <div style={{paddingRight: 10}}>

                    <Button color='success' onClick={() => {
                        setAdditionalFormData({is_parent: 0, parent_id: task.projectTaskId, project: project.id});
                        updateFormRequest(0, 1, true, 'tasks', project.project_type, () => {
                        })
                    }}>Add SubTask</Button>
                </div>
            </Stack>
            <div>
                <Stack direction={"row"} justifyContent={"center"} alignItems={"center"} marginTop={5}>
                    <ToggleButtonGroup value={view} onChange={(v, val) => {
                        setView(val)
                    }}>
                        <Button startDecorator={<DataDisplay/>} value="data">Data View</Button>
                        <Button startDecorator={<Form/>} value="form">Form View</Button>
                    </ToggleButtonGroup>
                </Stack>
                <div style={{padding: 10}}>
                    {view === 'form' && task.task_form!==null ?
                        <KForm additionalData={{project: project.id, task: task.id}} form={task.task_form}/> :
                        <div>
                            {
                                task.task_data.map((prop, key) => (
                                    <Accordion title='kk'>
                                        <AccordionDetails>
                                            <ResponsePreview data={prop}/>
                                        </AccordionDetails>
                                    </Accordion>
                                ))
                            }
                        </div>
                    }
                </div>
                {children}
            </div>
        </div>
    )
}

export function ProjectTask({project}) {
    let {setAdditionalFormData, get, updateFormRequest} = useAPI()
    const [task, setTasks] = React.useState([]);
    React.useEffect(() => {
        get(`forms/list/tasks/${project.id}`).then((r) => {
            setTasks(r.data)
        });
    }, [])
    return (
        <div className='animate__animated animate__fadeInDown'>
            <Stack direction={"row"} spacing={2}>
                <Button onClick={() => {
                    setAdditionalFormData({is_parent: 1, parent_id: 0, project: project.id});
                    updateFormRequest(0, 1, true, 'tasks', project.project_type, () => {
                    })
                }} startDecorator={<Plus/>}>Add Task</Button>
            </Stack>
            <br/>
            {
                task.map((prop) => (
                    <Task title={prop.name} task={prop} project={project}>

                        {
                            prop.sub_task.map((taskx) => (
                                <SubTask task={taskx} project={project}/>
                            ))
                        }
                    </Task>
                ))
            }
        </div>
    )
}

