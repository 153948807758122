import * as React from 'react'
import { LayersControl, MapContainer, Marker, Popup,TileLayer } from 'react-leaflet'
export default function KMap({children,center,zoom}){
    return(
        <MapContainer style={{height:500, width:"100%"}} center={[center.latitude, center.longitude]} zoom={zoom} scrollWheelZoom={false}>
            <LayersControl>
                <LayersControl.Overlay name="Hybrid Basemap">
                    <TileLayer
                    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                    />
                </LayersControl.Overlay>
                    {children}
            </LayersControl>
        
        <Marker position={[51.505, -0.09]}>
          <Popup>
            A pretty CSS3 popup. <br /> Easily customizable.
          </Popup>
        </Marker>
      </MapContainer>
    )
}