import * as React from 'react'
import {DataGrid} from '@mui/x-data-grid';
import {useAPI} from '../controllers/ContextProvider';

export default function KTable({ columns, rows, onRowClick,minHeight = window.innerHeight/1.3 }) {
    let { topics } = useAPI();
    return (
        <div>

            <DataGrid
            slotProps={{
                cell:{columnResize:true,isEditable:true},
            }}
            editMode='cell'
            rowBuffer={1}
                onCellClick={onRowClick}
                rows={rows}
                style={{ minHeight: minHeight }}
                columns={columns}
                initialState={{
                    pagination: {

                        paginationModel: { page: 0, pageSize: 10 },
                    },
                }}
                pageSizeOptions={[100, 300, 1000]}
            />
        </div>
    )
}