import {PaperMoney, Time} from '@icon-park/react'
import {Button, Card, CardContent, Typography} from '@mui/joy'
import {Grid, Stack} from '@mui/material'
import * as React from 'react'
import {useAPI} from '../../controllers/ContextProvider'
import {useLocation, useNavigate} from 'react-router'
import ResponseTabularView from '../responsetabular'

export default function Tribunals({project=0, ptype=0}){
    let nav = useNavigate()
    let {get,post,setFormId,setShowForm,business,setAdditionalFormData, updateFormRequest} = useAPI()
    const [tableWidth,setTableWidth]= React.useState({});
    const [expenses,setExpenses]= React.useState([]);
    const [,forceUpdate]= React.useState();
    React.useEffect(()=>{
        let colW = document.getElementById("ourcard").style.width;
        let c = colW.replace("px","")
        if(project===0){
            // get(`accounting/list/business/${business.id}/expenses`).then(r=>{
            //     setExpenses(r.data)
            // })
            get(`forms/list/responses/byTopic/10/groupByTag`).then(r=>{
                setExpenses(r.data)
            })
        }
        else{
            get(`accounting/list/project/${project}/expenses`).then(r=>{
                setExpenses(r.data)
            })
        }
    },[])
    return(
        <div className='animate__animated animate__fadeInDown'>
            <Stack direction="row">
                <Button style={{marginBottom:10}} onClick={()=>{
                    setFormId(10);
                    setShowForm(true);
                    // setAdditionalFormData({business:business.id});
                    // if(project===0){
                    // }
                    // else{
                    //     updateFormRequest(0,10,true,'project',ptype,()=>{forceUpdate({})})
                    //     setAdditionalFormData({project:project,business:business.id});
                    // }
                    }} startDecorator={<PaperMoney/>}>Add New Tribunal</Button>
                <br/>
                <br/>
            </Stack>
            <Card id='ourcard'>
                <CardContent>
                    <ResponseTabularView responses={expenses.data}/>
                    {/* <KTable rows={expenses} onRowClick={(e)=>{nav('/forms/expenses/preview',{state:e.row})}} columns={
                        [
                            {field:'id', headerName:"#",width:3},
                            {field:"name",headerName:"Tribunal name",width:200},
                            {field:"reason",headerName:"Date Recieved",width:200},
                            {field:"amount",headerName:"Amount",width:400},
                            {field:"created_by",headerName:"Initiated by",width:100},
                            {field:"status",headerName:"Status",width:100},

                        ]
                    }/> */}
                </CardContent>
            </Card>
        </div>
    )
}

export function ExpensesPreview(){
    let {get,post,setFormId,setShowForm,setAdditionalFormData } = useAPI()
    let data = useLocation().state
    const [expenses,setExpenses] = React.useState(data);
    React.useState(()=>{
        // get(`accounting/getExpense/`).then((r)=>{
        //     setExpenses(r.data);
        // });
    },[]);
    return(
        <div>
            <Card>
                <CardContent>
                    <Grid container>
                        <Grid item xs={12}>
                            <Typography>Expenditure Preview</Typography>
                            <Stack justifyContent={"space-between"} flexDirection={"row"}>
                                <Button onClick={()=>{setFormId(7);setShowForm(true);setAdditionalFormData({element:data.id})}}>Change Status</Button>
                                <Stack direction={"row"} spacing={1}>
                                <Button color='success'>Retirement</Button>
                                <Button color='warning'>Download Inventory CSV</Button>
                                <Button color='primary'>Upload Retirement CSV</Button>
                                </Stack>
                            </Stack>
                        </Grid>
                        <Grid item xs={6}>
                            <Typography>Amount</Typography>
                            <Typography>{data.amount}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                                <Typography>Description</Typography>

                                <Typography>{data.reason}</Typography>
                            <Typography>
                            </Typography>
                        </Grid>
                        <Grid item xs={12} md={12} marginTop={4}>
                            <Typography fontSize={17}><b>Remarks</b></Typography>
                        </Grid>
                        {
                            expenses.remarks.map((prop)=>(
                                <Grid item xs={12}>

                                    <div style={{borderLeft:"5px solid #0cf",margin:2, backgroundColor:"#eee"}}>
                                        <div style={{margin: 4, padding:10}}>
                                            <Typography startDecorator={<Time/>} style={{float:"right"}}><b>Created on :</b> {prop.created_at}</Typography>
                                            <Typography><b>Status:</b> {prop.status}</Typography>
                                            <Typography><b>Remarked by:</b> {prop.created_by}</Typography>
                                            <Typography><b>Comments :</b>{prop.comments}</Typography>
                                        </div>
                                    </div>
                                </Grid>
                            ))
                        }
                    </Grid>
                </CardContent>
            </Card>
        </div>
    )
}
